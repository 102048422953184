import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

import { NavigationBar } from '../NavigationBar';
import WalletConnectModal from '../components/WalletConnectModal';
import { StyledButton, Header, PageSection } from '../style/generalStyle';
import { Container } from '@material-ui/core';

function Login() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <NavigationBar />
      <Container>
        <PageSection>
          <FontAwesomeIcon
            icon={faWallet}
            style={{ fontSize: '3.75rem', margin: '1.5rem' }}
          />
          <Header>Connect your Ethereum Wallet</Header>
          <StyledButton
            onClick={() => {
              setOpen(true);
            }}
            style={{
              border: '1px solid #cf5059',
            }}
          >
            Connect Wallet
          </StyledButton>
        </PageSection>
      </Container>
      <WalletConnectModal isOpen={open} closeModal={() => setOpen(false)} />
    </>
  );
}

export default Login;
