import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Avatar, Button, Container, TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWallet,
  faPlusCircle,
  faPerson,
  faPen,
  faCheck,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { get, isNil, map, split } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

import { SideBar } from '../SideBar';
import { NavigationBar } from '../NavigationBar';
import { PageSection, Row } from '../style/generalStyle';
import {
  ButtonBlock,
  ContentBlock,
  Description,
  SectionContainer,
  Title,
} from '../style/pages/ProfileStyle';
import { profileDetails } from '../constant/context';
import AccountContext from '../context/accountContext';
import ethLogo from '../assets/eth.svg';
import { updateUser } from '../utils/dbModels';
import discordLogo from '../assets/discord.svg';
import twitterLogo from '../assets/twitter.svg';

function Profile() {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  const { account, refreshAccount } = useContext(AccountContext);
  const [username, setUsername] = useState(undefined);

  const [avatarUrl, setAvatarUrl] = useState(undefined);

  useEffect(() => {
    if (!isNil(get(account, 'username'))) {
      setUsername(get(account, 'username'));
    }
    if (!isNil(get(account, 'avatarUrl'))) {
      setAvatarUrl(get(account, 'avatarUrl'));
    }
  }, [account]);

  const onUploadAvatar = useCallback(async (imgFile) => {
    let formData = new FormData();
    formData.append('file', imgFile.data);
    const response = await fetch('http://localhost:8080/api/upload_avatar', {
      method: 'POST',
      body: formData,
    });
    const responseWithBody = await response.json();
    if (response) {
      setAvatarUrl(responseWithBody.publicUrl);
    }
  }, []);

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    onUploadAvatar(img);
  };

  const onUpdateSocialAccount = useCallback(
    async (method, socialAccount) => {
      await updateUser(account.id, {
        ...account,
        [method]: socialAccount,
      });
      await refreshAccount(account.mainAddress);
    },
    [account, refreshAccount]
  );

  const onConnectSocial = useCallback(
    (method) => {
      loginWithRedirect({
        connection: method,
        redirectUri: window.location.href,
      });
    },
    [loginWithRedirect]
  );

  const onDisconnectSocial = useCallback(
    (method) => {
      onUpdateSocialAccount(method, null);
    },
    [onUpdateSocialAccount]
  );

  useEffect(() => {
    if (isAuthenticated) {
      const [method, id] = split(user.sub, '|');

      if (method === 'twitter' && isNil(get(account, 'twitter'))) {
        onUpdateSocialAccount('twitter', {
          username: user.name,
          id,
          avatarUrl: user.picture,
        });
      } else if (method === 'oauth2' && isNil(get(account, 'discord'))) {
        const [, , discordId] = split(user.sub, '|');
        onUpdateSocialAccount('discord', {
          username: user.name,
          id: discordId,
          avatarUrl: user.picture,
        });
      }
    }
  }, [isAuthenticated, account, onUpdateSocialAccount, user]);

  const onSubmit = useCallback(async () => {
    await updateUser(account.id, {
      ...account,
      avatarUrl,
      username,
    });
    window.location.reload();
  }, [avatarUrl, username, account]);

  return (
    <div>
      <NavigationBar />
      <SideBar />
      <Container>
        <PageSection style={{ justifyContent: 'center' }}>
          <SectionContainer>
            <Row>
              <FontAwesomeIcon
                icon={faWallet}
                style={{ fontSize: '1.2rem', margin: '0 10px', color: 'white' }}
              />
              <Title style={{ color: 'white' }}>Wallets</Title>
            </Row>
            <Description style={{ color: 'white' }}>
              {profileDetails.wallets}
            </Description>
            <ContentBlock>
              <img
                src={ethLogo}
                alt="eth"
                style={{ width: 12, margin: '0 10px' }}
              />
              <Description>{account.mainAddress}</Description>
            </ContentBlock>
            {map(get(account, 'subAddresses', []), ({ mainAddress }) => (
              <ContentBlock>
                <img
                  src={ethLogo}
                  alt="eth"
                  style={{ width: 12, margin: '0 10px' }}
                />
                <Description>{mainAddress}</Description>
              </ContentBlock>
            ))}
            <ContentBlock>
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ fontSize: '1.2rem', margin: '0 10px', color: 'black' }}
              />
              <Description>{'Connect New Wallet'}</Description>
            </ContentBlock>
          </SectionContainer>
          <SectionContainer>
            <Row>
              <FontAwesomeIcon
                icon={faPerson}
                style={{ fontSize: '1.2rem', margin: '0 10px', color: 'white' }}
              />
              <Title style={{ color: 'white' }}>Profile</Title>
            </Row>
            <ContentBlock>
              <FontAwesomeIcon
                icon={faPen}
                style={{ fontSize: '1.2rem', margin: '0 10px', color: 'black' }}
              />
              <TextField
                placeholder={username}
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </ContentBlock>
            <ContentBlock>
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ fontSize: '1.2rem', margin: '0 10px', color: 'black' }}
              />
              <Description>{'Upload Avatar'}</Description>
              <Row style={{ justifyContent: 'space-between' }}>
                <input type="file" name="file" onChange={handleFileChange} />
                <Avatar
                  style={{ margin: '0 10px' }}
                  src={avatarUrl}
                  alt={'image'}
                />
              </Row>
            </ContentBlock>
            <ContentBlock
              onClick={() => {
                if (isNil(get(account, 'twitter'))) {
                  onConnectSocial('twitter');
                }
              }}
            >
              <img
                src={twitterLogo}
                alt="twitter"
                style={{ width: 12, marginLeft: 10 }}
              />
              {isNil(get(account, 'twitter')) ? (
                <Description>{'Connect To Twitter'}</Description>
              ) : (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Description>{get(account, 'twitter.username')}</Description>
                  <Button onClick={() => onDisconnectSocial('twitter')}>
                    Disconnect
                  </Button>
                </Row>
              )}
            </ContentBlock>
            <ContentBlock
              onClick={() => {
                if (isNil(get(account, 'discord'))) {
                  onConnectSocial('discord');
                }
              }}
            >
              <img
                src={discordLogo}
                alt="discord"
                style={{ width: 12, marginLeft: 10 }}
              />
              {isNil(get(account, 'discord')) ? (
                <Description>{'Connect To Discord'}</Description>
              ) : (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Description>{get(account, 'discord.username')}</Description>
                  <Button onClick={() => onDisconnectSocial('discord')}>
                    Disconnect
                  </Button>
                </Row>
              )}
            </ContentBlock>
            {(username !== get(account, 'username') ||
              avatarUrl !== get(account, 'avatarUrl')) && (
              <Row>
                <ButtonBlock style={{ background: '#cf5059' }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{
                      fontSize: '1.2rem',
                      margin: '0 10px',
                      color: 'black',
                    }}
                  />
                  <Description>Cancel</Description>
                </ButtonBlock>
                <ButtonBlock
                  onClick={onSubmit}
                  style={{ background: 'rgb(204, 232, 205)' }}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{
                      fontSize: '1.2rem',
                      margin: '0 10px',
                      color: 'black',
                    }}
                  />
                  <Description>Update</Description>
                </ButtonBlock>
              </Row>
            )}
          </SectionContainer>
        </PageSection>
      </Container>
    </div>
  );
}

export default Profile;
