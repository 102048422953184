import { Button } from '@material-ui/core';
import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin: 5vh;
`;

const Title = styled.div`
  padding: 0px;
  text-align: left;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
`;

const Description = styled.div`
  padding: 8px;
  text-align: left;
  color: black;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
  text-align: left;
  background: white;
  border: 1px solid rgba(1, 1, 1, 0.125);
  margin: 10px 0;
  border-radius: 3px;
`;

const ButtonBlock = styled(Button)`
  display: flex;
  width: 50%;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  font-size: 1rem;
  text-align: left;
  background: white;
  border: 1px solid rgba(1, 1, 1, 0.125);
  margin: 10px 10px;
  border-radius: 3px;
`;

export { SectionContainer, Title, Description, ContentBlock, ButtonBlock };
