import { startsWith } from 'lodash';
import {
  HomePage,
  LoginPage,
  AboutPage,
  ApplyPage,
  ListPage,
  CalendarPage,
  AllowListPage,
  NoticePage,
  ProfilePage,
} from '../pages';
import { backend_url } from '../constant/param';

export const routes = [
  {
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    path: '/allowlist',
    component: AllowListPage,
    exact: true,
  },
  {
    path: '/home',
    component: HomePage,
    exact: true,
  },
  {
    path: '/login',
    component: LoginPage,
    exact: true,
  },
  {
    path: '/about',
    component: AboutPage,
    exact: true,
  },
  {
    path: '/apply',
    component: ApplyPage,
    exact: true,
  },
  {
    path: '/list',
    component: ListPage,
    exact: true,
  },
  {
    path: '/calendar',
    component: CalendarPage,
    exact: true,
  },
  {
    path: '/notice',
    component: NoticePage,
    exact: true,
  },
  {
    path: '/profile',
    component: ProfilePage,
    exact: true,
  },
];

export const getBackendApi = (currentRoute) => {
  if (startsWith(currentRoute, 'http://localhost:3000')) {
    return 'http://localhost:8080/api';
  } else if (startsWith(currentRoute, 'https://premint.karmanft.xyz/')) {
    return backend_url;
  }
};
