import { map } from 'lodash';
import React from 'react';
import {
  Container,
  IconButton,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';
import { DoubleArrow, ExpandMore } from '@material-ui/icons';

import { aboutDetails, aboutMain, FAQDetails } from '../constant/context';
import { NavigationBar } from '../NavigationBar';
import { Header, PageSection, Row } from '../style/generalStyle';
import {
  DetailContainer,
  MainContainer,
  StyledAccordion,
} from '../style/pages/AboutStyle';

function About() {
  return (
    <div>
      <NavigationBar />
      <Container>
        <PageSection>
          <Header>ABOUT</Header>
          <MainContainer>{aboutMain}</MainContainer>
          {map(aboutDetails, (detail) => (
            <Row style={{ width: '30vw' }}>
              <IconButton style={{ color: '#cf5059' }}>
                <DoubleArrow />
              </IconButton>
              <DetailContainer>{detail}</DetailContainer>
            </Row>
          ))}
        </PageSection>
        <PageSection>
          <Header>FAQ</Header>
          {map(FAQDetails, (detail) => (
            <StyledAccordion>
              <AccordionSummary
                expandIcon={<ExpandMore style={{ color: '#cf5059' }} />}
              >
                <Typography>{detail.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{detail.answer}</Typography>
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </PageSection>
      </Container>
    </div>
  );
}

export default About;
