import React from 'react';

import { AppRoutes } from './routes';
import { routes } from './routes/routesConfig';

function App() {
  return <AppRoutes routes={routes} />;
}

export default App;
