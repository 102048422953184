import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Avatar,
  CircularProgress,
  Container,
  Snackbar,
} from '@material-ui/core';
import { find, get, isEmpty, isNil, split } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { CheckCircle, ClearRounded } from '@material-ui/icons';

import { SimpleNavigationBar } from '../SimpleNavigationBar';
import { PageSection, Row } from '../style/generalStyle';
import {
  Header,
  Description,
  TextContainer,
  InputLabel,
  InputContainer,
  InputField,
  SubmitButton,
  StyledButton,
} from '../style/pages/AllowListStyle';
import {
  checkAddressAvail,
  checkTwitterAvail,
  joinWaitList,
} from '../utils/dbModels';
import AccountContext from '../context/accountContext';
import { napoleon_twitter_id } from '../constant/param';
import { getBackendApi } from '../routes/routesConfig';

function AllowList() {
  const { currentAddress } = useContext(AccountContext);
  const [email, setEmail] = useState(null);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [alertMessage, setAlertMessage] = useState(null);
  const [successMessage, setSuceessMessage] = useState(null);

  const onSubmit = useCallback(async () => {
    if (isNil(currentAddress)) {
      setAlertMessage('請先連接錢包');
    } else if (isEmpty(get(user, 'name'))) {
      setAlertMessage('請連接 Twitter');
    } else if (isEmpty(email)) {
      setAlertMessage('請填寫 Email');
    } else {
      const addressAvail = await checkAddressAvail(currentAddress);
      const twitterAvail = await checkTwitterAvail(get(user, 'name'));
      const emailAvail = await checkAddressAvail(email);
      if (!(addressAvail && twitterAvail && emailAvail)) {
        setAlertMessage('該帳號已經加入 waitlist');
      } else {
        await joinWaitList({
          mainAddress: currentAddress,
          twitter: user.name,
          email,
        });
        setSuceessMessage('已成功加入 waitlist');
      }
    }
  }, [user, email, currentAddress]);

  const [verifying, setVerifying] = useState(false);
  const [verification, setVerification] = useState(false);

  const isValidSubmitData = useMemo(() => {
    return (
      !isNil(currentAddress) &&
      !isEmpty(get(user, 'name')) &&
      !isEmpty(email) &&
      !verifying &&
      verification
    );
  }, [currentAddress, user, email, verifying, verification]);

  const getFollowersVerification = useCallback(() => {
    (async () => {
      try {
        const response = await axios({
          url: `${getBackendApi(window.location.href)}/get_followers`,
          method: 'POST',
          data: {
            twitterId: napoleon_twitter_id,
          },
        });
        const followersData = response.data;
        const [, userId] = split(user.sub, '|');
        if (!isNil(find(followersData, (follower) => follower.id === userId))) {
          setVerification(true);
        }
        setVerifying(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user]);

  const startVerify = useCallback(() => {
    if (isAuthenticated) {
      setVerifying(true);
      getFollowersVerification();
    }
  }, [isAuthenticated, getFollowersVerification]);

  return (
    <div>
      <SimpleNavigationBar />
      <Container>
        <PageSection style={{ flexDirection: 'row' }}>
          <TextContainer>
            <Header>Join our waitlist and get early access.</Header>
            <Description>
              Join our waitlist to get access to the all-inclusive NFT tool you
              have been waiting for.
            </Description>
            <InputContainer>
              {!isAuthenticated && (
                <StyledButton
                  onClick={() =>
                    loginWithRedirect({
                      connection: 'twitter',
                    })
                  }
                >
                  Connect With Twitter
                </StyledButton>
              )}
              {isAuthenticated && (
                <Row style={{ margin: '10px 0', alignItems: 'center' }}>
                  <Avatar alt="avatar" src={user.picture} />
                  <Description style={{ margin: '0 10px' }}>
                    {user.name}
                  </Description>
                  <Row style={{ flexWrap: 'wrap' }}>
                    <StyledButton onClick={logout}>Disconnect</StyledButton>
                    {!verification && (
                      <StyledButton
                        onClick={() =>
                          window.open(
                            'https://twitter.com/Napoleon_pass',
                            '_blank',
                            'noopener,noreferrer'
                          )
                        }
                      >
                        Follow
                      </StyledButton>
                    )}
                    {!verification && (
                      <StyledButton onClick={startVerify}>Verify</StyledButton>
                    )}

                    {verifying && <CircularProgress />}
                    {verification && !verifying && (
                      <CheckCircle style={{ color: 'rgb(204, 232, 205)' }} />
                    )}
                    {!verification && !verifying && (
                      <ClearRounded style={{ color: '#cf5059' }} />
                    )}
                  </Row>
                </Row>
              )}
              <InputLabel>E-mail</InputLabel>
              <InputField
                type="email"
                placeholder={'youremail@here.com'}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <SubmitButton onClick={onSubmit} disabled={!isValidSubmitData}>
                Submit
              </SubmitButton>
            </InputContainer>
          </TextContainer>
        </PageSection>
      </Container>
      {!isNil(alertMessage) && (
        <Snackbar
          style={{ border: '1px solid #cf5059' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={true}
          onClose={() => setAlertMessage(null)}
          message={alertMessage}
        />
      )}
      {!isNil(successMessage) && (
        <Snackbar
          style={{ border: '1px solid rgb(204, 232, 205)' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!isNil(successMessage)}
          onClose={() => setSuceessMessage(null)}
          message={successMessage}
        />
      )}
    </div>
  );
}

export default AllowList;
