import styled from 'styled-components';
import { Avatar, Dialog } from '@material-ui/core';

const Header = styled.div`
  padding: 20px;
  text-transform: none;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: white;
  line-height: 1.46;
  font-size: 1.8rem;
  border: 2px solid white;
  margin: 4vh;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 56vw;
  height: 50vh;
  border: 2px solid white;
  border-radius: 10px;
`;

const CalandarRow = styled.div`
  flex-direction: column;
  width: 8vw;
  height: 100%;
`;

const DateContainer = styled.div`
  text-align: center;
  width: 8vw;
  padding-top: 3vh;
  padding-bottom: 3vh;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: white;
  font-size: 1.2rem;
  border-bottom: 2px solid white;
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const StyledAvatar = styled(Avatar)`
  width: 5vh;
  height: 5vh;
`;

const EventDialog = styled(Dialog)`
  && .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
`;

export {
  Header,
  CalendarContainer,
  CalandarRow,
  DateContainer,
  AvatarContainer,
  StyledAvatar,
  EventDialog,
};
