import faunadb, { query as q } from 'faunadb';
import dotenv from 'dotenv';
import { filter, isEmpty } from 'lodash';

dotenv.config();

const client = new faunadb.Client({ secret: process.env.REACT_APP_FAUNA_KEY });

export const createUser = async (mainAddress) => {
  let data;
  try {
    data = await client.query(
      q.Create(q.Collection('users'), {
        data: {
          mainAddress,
        },
      })
    );
    if (data.name === 'BadRequest') return;
  } catch (error) {
    return;
  }
  const user = data.data;
  user.id = data.ref.value.id;
  return user;
};

export const getUserFromAddress = async (address) => {
  try {
    const users = await client.query(
      q.Map(
        q.Paginate(q.Documents(q.Collection('users'))),
        q.Lambda((x) => q.Get(x))
      )
    );
    return filter(users.data, (user) => user.data.mainAddress === address);
  } catch {
    return;
  }
};

export const updateUser = async (id, data) => {
  try {
    console.log(data);
    const user = await client.query(
      q.Update(q.Ref(q.Collection('users'), id), { data })
    );
    user.data.id = user.ref.value.id;
    return user.data;
  } catch {
    return;
  }
};

export const loginUser = async (address) => {
  try {
    let userData = await client.query(
      q.Get(q.Match(q.Index('user_by_address'), address))
    );
    console.log('data', userData);
    userData.data.id = userData.ref.value.id;
    return userData.data;
  } catch (error) {
    return;
  }
};

const getDate = (date) =>
  `${date.getMonth() + 1} ${date.getDate()}, ${date.getFullYear()}`;

export const createPost = async (postData) => {
  const { mintDate, raffleDate, ...otherData } = postData;

  const date = new Date();
  let data = await client.query(
    q.Create(q.Collection('posts'), {
      data: {
        ...otherData,
        mintDate: getDate(mintDate),
        raffleDate: getDate(raffleDate),
        createdTime: getDate(date),
      },
    })
  );
  data.data.id = data.ref.value.id;
  return data.data;
};

export const getPosts = async () => {
  let allBlogs = await client.query(
    q.Map(
      q.Paginate(q.Documents(q.Collection('posts'))),
      q.Lambda('X', q.Get(q.Var('X')))
    )
  );
  return allBlogs.data;
};

export const getPost = async (id) => {
  try {
    let blog = await client.query(q.Get(q.Ref(q.Collection('posts'), id)));
    blog.data.id = blog.ref.value.id;
    return blog.data;
  } catch (error) {
    return;
  }
};

export const updatePost = async (id, data) => {
  try {
    let blog = await client.query(
      q.Update(q.Ref(q.Collection('posts'), id), { data: { ...data } })
    );
    blog.data.id = blog.ref.value.id;
    return blog.data;
  } catch {
    return;
  }
};

export const joinWaitList = async (userData) => {
  let data;
  try {
    data = await client.query(
      q.Create(q.Collection('waitlist'), {
        data: {
          ...userData,
        },
      })
    );
    if (data.name === 'BadRequest') return;
  } catch (error) {
    return;
  }
  const user = data.data;
  user.id = data.ref.value.id;
  return user;
};

export const checkAddressAvail = async (address) => {
  try {
    const waitlists = await client.query(
      q.Map(
        q.Paginate(q.Documents(q.Collection('waitlist'))),
        q.Lambda((x) => q.Get(x))
      )
    );
    return isEmpty(
      filter(waitlists.data, (user) => user.data.mainAddress === address)
    );
  } catch {
    return;
  }
};

export const checkTwitterAvail = async (twitter) => {
  try {
    const waitlists = await client.query(
      q.Map(
        q.Paginate(q.Documents(q.Collection('waitlist'))),
        q.Lambda((x) => q.Get(x))
      )
    );
    return isEmpty(
      filter(waitlists.data, (user) => user.data.twitter === twitter)
    );
  } catch {
    return;
  }
};

export const checkEmailAvail = async (email) => {
  try {
    const waitlists = await client.query(
      q.Map(
        q.Paginate(q.Documents(q.Collection('waitlist'))),
        q.Lambda((x) => q.Get(x))
      )
    );
    return isEmpty(filter(waitlists.data, (user) => user.data.email === email));
  } catch {
    return;
  }
};
