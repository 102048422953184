import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './utils/history';
import { Web3Provider } from '@ethersproject/providers';
import { createRoot } from 'react-dom/client';
import { getConfig } from './config';

import App from './App';
import reportWebVitals from './reportWebVitals';
import AccountContext, { useAccountContext } from './context/accountContext';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

function AppWithProvider() {
  const accountContext = useAccountContext();

  return (
    <Auth0Provider {...providerConfig}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <AccountContext.Provider value={accountContext}>
          <App />
        </AccountContext.Provider>
      </Web3ReactProvider>
    </Auth0Provider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWithProvider />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
