import React, { useContext, useState } from 'react';
import { get, isNull, map, truncate } from 'lodash';
import { IconButton, Avatar, Divider } from '@material-ui/core';
import { CheckOutlined } from '@material-ui/icons';
import { useWeb3React } from '@web3-react/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import AccountContext from '../context/accountContext';
import avatar from '../assets/avatar.png';

import {
  StyledButton,
  Row,
  Column,
  Menu,
  MenuTypography,
} from '../style/generalStyle';
import { SubAdressDialog } from './SubAddressDialog';

export function ProfileMenu({ anchorMenu, setAnchorMenu }) {
  const { account, currentAddress, setCurrentAddress } =
    useContext(AccountContext);

  const { deactivate } = useWeb3React();

  const refreshState = () => {
    window.localStorage.setItem('provider', undefined);
    localStorage.removeItem('address');
    window.location.reload();
  };

  const onLogout = () => {
    refreshState();
    deactivate();
  };

  const [open, setOpen] = useState(false);

  const history = useHistory();

  return (
    <Menu
      open={!isNull(anchorMenu)}
      anchorEl={anchorMenu}
      onClose={() => {
        setAnchorMenu(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Row onClick={() => setCurrentAddress(account.mainAddress)}>
        <Avatar
          style={{ marginRight: '1vw' }}
          alt="img"
          src={get(account, 'avatarUrl')}
        />
        <Column>
          <MenuTypography>{get(account, 'username', 'default')}</MenuTypography>
          <MenuTypography>
            {truncate(account.mainAddress, { length: 10 })}
          </MenuTypography>
        </Column>
        {account.mainAddress === currentAddress && (
          <IconButton style={{ color: 'green' }}>
            <CheckOutlined />
          </IconButton>
        )}
      </Row>
      {map(account.subAddresses, (account) => (
        <Row
          style={{ width: '100%' }}
          key={account.mainAddress}
          onClick={() => setCurrentAddress(account.mainAddress)}
        >
          <Avatar style={{ marginRight: '1vw' }} alt="img" src={avatar} />
          <Column>
            <MenuTypography>
              {get(account, 'username', 'sub Account')}
            </MenuTypography>
            <MenuTypography>
              {truncate(account.mainAddress, { length: 10 })}
            </MenuTypography>
          </Column>
          {account.mainAddress === currentAddress && (
            <IconButton style={{ color: 'green' }}>
              <CheckOutlined />
            </IconButton>
          )}
        </Row>
      ))}
      <Divider light />
      <StyledButton
        onClick={() => {
          setOpen(true);
        }}
      >
        {'加入現有帳戶'}
      </StyledButton>
      <StyledButton
        onClick={() => {
          history.push('./profile');
        }}
      >
        {'管理帳戶'}
      </StyledButton>
      <StyledButton onClick={onLogout}>{`登出  ${truncate(currentAddress, {
        length: 10,
      })}`}</StyledButton>
      <SubAdressDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Menu>
  );
}

ProfileMenu.propTypes = {
  anchorMenu: PropTypes.object,
  setAnchorMenu: PropTypes.func,
};
