import styled from 'styled-components';
import { Modal, Typography, Box } from '@material-ui/core';

const StyledModal = styled(Modal)`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
`;

const CardContainer = styled(Box)`
  position: relative;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  margin: 10px;
  padding: 0px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  max-width: 800px;
  min-width: fit-content;
  max-height: 100%;
  overflow: auto;
`;

const Card = styled(Box)`
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 0px;
  border: 1px solid rgba(195, 195, 195, 0.14);
`;

const Icon = styled.img`
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  overflow: visible;
  box-shadow: none;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`;

const Header = styled(Typography)`
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-top: 0.5em;
  color: rgb(12, 12, 13);
`;

const Description = styled(Typography)`
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 0.333em 0px;
  color: rgb(169, 169, 188);
`;

export { StyledModal, CardContainer, Card, Header, Description, Icon };
