import React, { useContext, useEffect, useState, useCallback } from 'react';
import { get, isEqual, isNil, truncate } from 'lodash';
import { useHistory } from 'react-router-dom';
import { IconButton, AppBar, Toolbar } from '@material-ui/core';
import { PeopleOutline } from '@material-ui/icons';
import { useWeb3React } from '@web3-react/core';

import logo from './assets/logo.png';
import AccountContext from './context/accountContext';
import { StyledButton, StyledTypography, Row } from './style/generalStyle';
import { ProfileMenu } from './components/ProfileMenu';
import WalletConnectModal from './components/WalletConnectModal';
import useConnect2Phantom from './components/PhantomConnectModal.tsx';

export const NavigationBar = () => {
  const [open, setOpen] = useState(false);

  const { account: ethAddress } = useWeb3React();
  const { solAddress } = useConnect2Phantom();

  const { currentAddress, account, refreshAccount, setCurrentAddress } =
    useContext(AccountContext);
  const history = useHistory();
  const [anchorMenu, setAnchorMenu] = useState(null);

  const onOpenMenu = (event) => {
    setAnchorMenu(event.target);
  };

  const onLogin = useCallback(
    async (address) => {
      await refreshAccount(address);
      localStorage.setItem('address', address);
    },
    [refreshAccount]
  );

  useEffect(() => {
    const existedAddress = get(account, 'mainAddress');
    if (!isNil(ethAddress) && !isEqual(existedAddress, ethAddress)) {
      onLogin(ethAddress);
    }
    if (!isNil(solAddress) && !isEqual(existedAddress, solAddress)) {
      onLogin(solAddress.toString());
    }
  }, [ethAddress, solAddress, account, onLogin]);

  useEffect(() => {
    const localAddress = localStorage.getItem('address');

    if (localAddress !== 'null' && isNil(currentAddress)) {
      onLogin(localAddress);
    }

    if (!isNil(get(account, 'mainAddress'))) {
      setCurrentAddress(get(account, 'mainAddress'));
    }
  }, [account, setCurrentAddress, currentAddress, onLogin]);

  return (
    <AppBar
      position="fixed"
      style={{
        background: 'black',
        boxShadow: 'none',
      }}
    >
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          zIndex: 10,
          width: '100%',
          padding: 0,
        }}
      >
        <Row>
          <img
            src={logo}
            alt="Premint"
            style={{
              height: 60,
              padding: '10px',
              marginRight: '10px',
            }}
            onClick={() => {
              history.push('/home');
            }}
          />
          <StyledButton
            onClick={() => {
              history.push('/home');
            }}
          >
            HOME
          </StyledButton>
          <StyledButton
            onClick={() => {
              history.push('/about');
            }}
          >
            ABOUT
          </StyledButton>
          <StyledButton
            onClick={() => {
              window.open(
                'https://opensea.io/account',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          >
            OPENSEA
          </StyledButton>
          <StyledButton
            onClick={() => {
              history.push('./list');
            }}
          >
            LIST
          </StyledButton>
          <StyledButton
            onClick={() => {
              history.push('/calendar');
            }}
          >
            ACTIVITY
          </StyledButton>
        </Row>

        {isNil(currentAddress) ? (
          <Row style={{ justifyContent: 'flex-end' }}>
            <StyledButton
              onClick={() => {
                setOpen(true);
              }}
            >
              Login
            </StyledButton>
            <StyledButton
              onClick={() => {
                history.push('/calendar');
              }}
            >
              Get Started
            </StyledButton>
          </Row>
        ) : (
          <Row style={{ justifyContent: 'flex-end' }}>
            <StyledTypography>
              {truncate(currentAddress, { length: 10 })}
            </StyledTypography>
            <IconButton onClick={onOpenMenu} style={{ color: ' #cf5059' }}>
              <PeopleOutline />
            </IconButton>
            <ProfileMenu
              anchorMenu={anchorMenu}
              setAnchorMenu={setAnchorMenu}
            />
          </Row>
        )}
      </Toolbar>
      <WalletConnectModal isOpen={open} closeModal={() => setOpen(false)} />
    </AppBar>
  );
};
