import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import PropTypes from 'prop-types';

import { connectors } from '../utils/connectors';
import metaMask from '../assets/metamask.svg';
import phantomIcon from '../assets/phantom.svg';

import {
  StyledModal,
  CardContainer,
  Card,
  Header,
  Description,
  Icon,
} from '../style/walletConnectModalStyle';
import { signMessage } from '../utils/signMessage';
import useConnect2Phantom from './PhantomConnectModal.tsx';

export default function WalletConnectModal({ isOpen, closeModal }) {
  const { activate } = useWeb3React();
  const { connectHandler } = useConnect2Phantom();

  const setProvider = (type) => {
    window.localStorage.setItem('provider', type);
  };

  const onConnect = async (method) => {
    try {
      await activate(connectors[method]);
      await handleSignin();
      setProvider(method);
      closeModal();
    } catch (err) {
      console.log(err);
    }
  };

  const [signatures, setSignatures] = useState([]);

  const handleSignin = async () => {
    const sig = await signMessage({
      message: 'Signin Napoléon',
    });
    if (sig) {
      setSignatures([...signatures, sig]);
    }
  };

  return (
    <StyledModal open={isOpen} onClose={closeModal}>
      <CardContainer>
        <Card
          onClick={() => {
            onConnect('injected');
          }}
        >
          <Icon src={metaMask} />
          <Header>MetaMask</Header>
          <Description>Connect to your MetaMask Wallet</Description>
        </Card>
        <Card onClick={connectHandler}>
          <Icon src={phantomIcon} />
          <Header>Phantom</Header>
          <Description>Connect to your Phantom Wallet</Description>
        </Card>
      </CardContainer>
    </StyledModal>
  );
}

WalletConnectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
