import { useEffect, useState } from 'react';
import { PublicKey } from '@solana/web3.js';

type PhantomEvent = 'disconnect' | 'connect' | 'accountChanged';

interface ConnectOpts {
  onlyIfTrusted: boolean;
}

interface PhantomProvider {
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>;
  disconnect: () => Promise<void>;
  on: (event: PhantomEvent, callback: (args: PublicKey) => void) => void;
  isPhantom: boolean;
}

type WindowWithSolana = Window & {
  solana?: PhantomProvider;
};

interface ReturnType {
  pubKey: PublicKey | null;
  solAddress: string | null;
  walletAvail: boolean;
  connected: boolean;
  connectHandler: React.MouseEventHandler<HTMLButtonElement>;
  disconnectHandler: React.MouseEventHandler<HTMLButtonElement>;
}

const useConnect2Phantom = (): ReturnType => {
  const [walletAvail, setWalletAvail] = useState(false);
  const [provider, setProvider] = useState<PhantomProvider | null>(null);
  const [connected, setConnected] = useState(false);
  const [pubKey, setPubKey] = useState<PublicKey | null>(null);
  const [solAddress, setSolAddress] = useState<string | null>(null);

  useEffect(() => {
    if ('solana' in window) {
      const solWindow = window as WindowWithSolana;
      if (solWindow?.solana?.isPhantom) {
        setProvider(solWindow.solana);
        setWalletAvail(true);
        // Attemp an eager connection
        solWindow.solana.connect({ onlyIfTrusted: true });
      }
    }
  }, []);

  useEffect(() => {
    provider?.on('connect', (publicKey: PublicKey) => {
      setConnected(true);
      setPubKey(publicKey);
      setSolAddress(publicKey.toString());
    });
    provider?.on('disconnect', () => {
      setConnected(false);
      setPubKey(null);
      setSolAddress(null);
    });
  }, [provider]);

  const connectHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    provider?.connect().catch((err) => {
      console.error('connect ERROR:', err);
    });
  };

  const disconnectHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    provider?.disconnect().catch((err) => {
      console.error('disconnect ERROR:', err);
    });
  };

  return {
    pubKey,
    solAddress,
    walletAvail,
    connected,
    connectHandler,
    disconnectHandler,
  };
};

export default useConnect2Phantom;
