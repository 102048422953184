import styled from 'styled-components';
import { Typography, Popover, Dialog, Button } from '@material-ui/core';

const PageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  margin: 10vh 0;
`;

const StyledButton = styled(Button)`
  color: #cf5059;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #cf5059;
    color: black;
    border-radius: 4px;
    border-color: #cf5059;
  }
`;

const FilledButton = styled(Button)`
  color: black;
  background-color: #cf5059;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: black;
    color: #cf5059;
    border-radius: 4px;
    border: 1px solid #cf5059;
  }
`;

const StyledTypography = styled(Typography)`
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Menu = styled(Popover)`
  && .MuiPopover-paper {
    padding: 10px;
  }
`;

const MenuTypography = styled(Typography)`
  color: black;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 0.5rem;
  width: 15vw;
`;

const Section = styled.div`
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

const Header = styled.div`
  margin: 0 0 6px;
  padding: 0;
  text-transform: none;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: white;
  line-height: 1.46;
  font-size: 3rem;
`;

const SubHeader = styled.div`
  margin: 0 0 6px;
  padding: 0;
  text-transform: none;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: white;
  line-height: 1.46;
  font-size: 2rem;
`;

const StyledDialog = styled(Dialog)`
  && .MuiDialog-paper {
    padding: 20px;
  }
`;

export {
  PageSection,
  StyledTypography,
  StyledButton,
  FilledButton,
  Row,
  Column,
  Menu,
  MenuTypography,
  Section,
  Header,
  SubHeader,
  StyledDialog,
};
