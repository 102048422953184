import styled from 'styled-components';
import { Checkbox, Dialog } from '@material-ui/core';

const MainContainer = styled.div`
  justify-contents: flex-start;
  margin: 5vh 0 5vh;
  max-width: 40vw;
  border: 1px solid white;
  padding: 24px;
  text-align: left;
  color: white;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
`;

const DetailContainer = styled.div`
  width: 100%;
  margin: auto;
  padding: 24px;
  text-align: left;
  color: white;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
`;

const StyledCheckbox = styled(Checkbox)`
  && .MuiIconButton-label {
    border: 1px solid white;
  }
`;

const EventDialog = styled(Dialog)`
  && .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 450px;
  height: 300px;
  margin: 10px;
`;

const DialogHeader = styled.div`
  width: 100%;
  padding: 12px;
  text-align: left;
  color: black;
  font-size: 1.5rem;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
`;

const BoldDialogDetail = styled.div`
  padding: 0px;
  text-align: left;
  color: black;
  font-size: 0.8rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
`;

const ThinDialogDetail = styled.div`
  padding: 8px;
  text-align: left;
  color: black;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const InputField = styled.input`
  margin: 0 0 13px;
  background: transparent;
  border: 1px solid #black;
  border-radius: 5px;
  color: black;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  line-height: 12px;
  padding: 0 12px;
  width: calc(80% - 24px);
`;

export {
  MainContainer,
  DetailContainer,
  StyledCheckbox,
  EventDialog,
  DialogContainer,
  DialogHeader,
  BoldDialogDetail,
  ThinDialogDetail,
  CheckBoxContainer,
  Row,
  InputField,
};
