import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ethers } from 'ethers';
import { includes, isNil, map } from 'lodash';

import { StyledButton, StyledDialog } from '../style/generalStyle';
import AccountContext from '../context/accountContext';

export function SubAdressDialog({ open, onClose }) {
  const { addSubAddresses, account } = useContext(AccountContext);
  const [address, setAddress] = useState(null);

  const [error, setError] = useState(null);

  useEffect(() => {
    const subAddresses = map(account.subAddresses, 'mainAddress');
    if (!ethers.utils.isAddress(address)) {
      setError('地址無效');
    } else if (
      includes(subAddresses, address) ||
      address === account.mainAddress
    ) {
      setError('地址重複');
    } else {
      setError(null);
    }
  }, [address, account]);

  return (
    <StyledDialog onClose={onClose} open={open}>
      <TextField
        id="standard-basic"
        label="Address"
        variant="standard"
        style={{ width: '15vw' }}
        onChange={(event) => {
          setAddress(event.target.value);
        }}
        error={!isNil(error)}
        helperText={error || ''}
      />
      <StyledButton
        style={{
          color: 'white',
          backgroundColor: 'black',
          textAlign: 'center',
          margin: 10,
        }}
        onClick={() => {
          addSubAddresses(address);
          onClose();
        }}
      >
        加入現有帳戶
      </StyledButton>
    </StyledDialog>
  );
}

SubAdressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
