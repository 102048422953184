import React from 'react';
import { Box } from '@material-ui/core';
import {
  HomeOutlined,
  CalendarToday,
  Notifications,
  People,
} from '@material-ui/icons';
import { map } from 'lodash';

import { Column } from './style/generalStyle';
import {
  IconTextContainer,
  SideBarButton,
  SideBarIcon,
  StyledToolbar,
} from './style/pages/ListStyle';
import { useHistory } from 'react-router-dom';

const sideBarRoutes = [
  {
    name: 'Home',
    route: '/home',
    icon: <HomeOutlined />,
  },
  {
    name: 'Calendar',
    route: '/calendar',
    icon: <CalendarToday />,
  },
  {
    name: 'Notice',
    route: '/notice',
    icon: <Notifications />,
  },
  {
    name: 'Profile',
    route: '/profile',
    icon: <People />,
  },
];

export function SideBar() {
  const history = useHistory();

  const renderSideBar = ({ name, icon, route }) => {
    return (
      <IconTextContainer key={name}>
        <SideBarButton
          onClick={() => {
            history.push(route);
          }}
        >
          <SideBarIcon>{icon}</SideBarIcon>
          {name}
        </SideBarButton>
      </IconTextContainer>
    );
  };

  return (
    <Box
      style={{
        background: 'transparent',
        boxShadow: 'none',
        position: 'fixed',
      }}
    >
      <StyledToolbar>
        <Column>
          {map(sideBarRoutes, ({ name, icon, route }) =>
            renderSideBar({ name, icon, route })
          )}
        </Column>
      </StyledToolbar>
    </Box>
  );
}
