import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Avatar, Container } from '@material-ui/core';
import { filter, get, includes, isEmpty, isNil, map } from 'lodash';
import { Favorite } from '@material-ui/icons';

import { SideBar } from '../SideBar';
import { NavigationBar } from '../NavigationBar';
import { Column, PageSection, Row } from '../style/generalStyle';
import { getPosts } from '../utils/dbModels';
import AccountContext from '../context/accountContext';
import {
  CheckoutButton,
  EventDescription,
  EventTitle,
  NoticeContainer,
} from '../style/pages/NoticeStyle';

import avatar from '../assets/avatar.png';
import { EventDialog } from '../style/pages/CalendarStyle';
import CalandarEventDialog from '../components/CalandarEventDialog';

function Notice() {
  const { currentAddress } = useContext(AccountContext);
  const [postData, setPostData] = useState(null);

  const getPostData = useCallback(async () => {
    const posts = await getPosts();
    setPostData(posts);
  }, [setPostData]);

  useEffect(() => {
    if (isNil(postData)) {
      getPostData();
    }
  }, [getPostData, postData]);

  const [userLottery, setUserLottery] = useState([]);

  useEffect(() => {
    if (!isEmpty(postData)) {
      const lottery = filter(postData, ({ data }) =>
        includes(get(data, 'lotteryList', []), currentAddress)
      );
      setUserLottery(lottery);
    }
  }, [postData, currentAddress]);

  const [eventData, setEventData] = useState(null);

  return (
    <div>
      <NavigationBar />
      <SideBar />
      <Container>
        <PageSection style={{ justifyContent: 'center' }}>
          <NoticeContainer>
            {map(userLottery, ({ data, ref }) => (
              <div
                style={{
                  border: '1px solid rgb(113, 118, 123)',
                  padding: 10,
                }}
              >
                <Row
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Favorite
                    style={{
                      color: '#cf5059',
                      fontSize: '2rem',
                    }}
                  />
                  <Row style={{ width: '100%' }}>
                    <Avatar
                      style={{ margin: '0 10px' }}
                      src={avatar}
                      alt={data.eventNameTitle}
                    />
                    <Column style={{ alignItems: 'flex-start' }}>
                      <EventTitle>{data.eventNameTitle}</EventTitle>
                      <EventDescription>
                        {'you won the raffle'}
                      </EventDescription>
                    </Column>
                  </Row>
                  <CheckoutButton onClick={() => setEventData(data)}>
                    {'Check out >>'}
                  </CheckoutButton>
                </Row>
                {!isNil(eventData) && (
                  <EventDialog open={true} onClose={() => setEventData(null)}>
                    <div
                      style={{
                        minWidth: 1000,
                        minHeight: 600,
                      }}
                    >
                      <CalandarEventDialog data={eventData} id={ref.value.id} />
                    </div>
                  </EventDialog>
                )}
              </div>
            ))}
          </NoticeContainer>
        </PageSection>
      </Container>
    </div>
  );
}

export default Notice;
