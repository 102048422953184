import React, { useCallback, useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { compact, get, isNil, map } from 'lodash';

import { SideBar } from '../SideBar';
import { NavigationBar } from '../NavigationBar';
import { PageSection } from '../style/generalStyle';
import {
  Header,
  CalendarContainer,
  DateContainer,
  CalandarRow,
  StyledAvatar,
  AvatarContainer,
  EventDialog,
} from '../style/pages/CalendarStyle';
import { getPosts } from '../utils/dbModels';
import CalandarEventDialog from '../components/CalandarEventDialog';

function Calendar() {
  const [postData, setPostData] = useState(null);

  const getPostData = useCallback(async () => {
    const posts = await getPosts();
    setPostData(posts);
  }, [setPostData]);

  useEffect(() => {
    if (isNil(postData)) {
      getPostData();
    }
  }, [getPostData, postData]);

  const getDatesInOneWeek = () => {
    const today = new Date();
    const arr = Array(7);
    const datesInOneWeek = map(arr, (_, index) => {
      const newDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + index
      );
      return newDate;
    });
    return datesInOneWeek;
  };

  const [eventData, setEventData] = useState(null);

  return (
    <div>
      <NavigationBar />
      <SideBar />
      <Container>
        <PageSection style={{ justifyContent: 'center' }}>
          <Header>Launch Calendar</Header>
          <CalendarContainer>
            {map(getDatesInOneWeek(), (date, index) => (
              <CalandarRow
                key={date}
                style={{
                  border: index === 0 ? '1px dashed white' : '',
                }}
              >
                <DateContainer>{`${
                  date.getMonth() + 1
                }/${date.getDate()}`}</DateContainer>
                {compact(
                  map(postData, ({ data, ref }, index) => {
                    const { mintDate, eventNameTitle } = data;
                    if (new Date(mintDate).getDate() === date.getDate()) {
                      return (
                        <>
                          <AvatarContainer
                            key={index}
                            onClick={() => setEventData(data)}
                          >
                            <StyledAvatar
                              style={{ border: '2px solid white' }}
                              alt={eventNameTitle}
                              src={get(data, 'avatar')}
                            />
                          </AvatarContainer>
                          {!isNil(eventData) && (
                            <EventDialog
                              open={true}
                              onClose={() => setEventData(null)}
                            >
                              <div
                                style={{
                                  minWidth: 1000,
                                  minHeight: 600,
                                }}
                              >
                                <CalandarEventDialog
                                  data={eventData}
                                  id={ref.value.id}
                                />
                              </div>
                            </EventDialog>
                          )}
                        </>
                      );
                    }
                  })
                )}
              </CalandarRow>
            ))}
          </CalendarContainer>
        </PageSection>
      </Container>
    </div>
  );
}

export default Calendar;
