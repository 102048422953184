import {
  compact,
  forEach,
  get,
  isBoolean,
  isDate,
  isEmpty,
  isNil,
  isNumber,
  keys,
  map,
  toNumber,
} from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';

import {
  applyDetials,
  applyHeaderText,
  applySubHeaderText,
} from '../constant/context';
import { NavigationBar } from '../NavigationBar';
import {
  Header,
  PageSection,
  StyledButton,
  SubHeader,
} from '../style/generalStyle';
import {
  DetailContainer,
  ThinDialogDetail,
  MainContainer,
  StyledCheckbox,
  CheckBoxContainer,
  Row,
  EventDialog,
} from '../style/pages/ApplyStyle';
import { postSchema } from '../constant/schema';
import ethLogo from '../assets/eth.svg';
import solLogo from '../assets/sol.svg';
import { createPost } from '../utils/dbModels';
import PreviewEventDialog from '../components/PreviewEventDialog';
import { isValidHttpUrl } from '../utils/typeValidation';
import { getBackendApi } from '../routes/routesConfig';

const process = [
  {
    title: 'Step 1',
    postKey: [
      'eventNameTitle',
      'projectName',
      'mintDate',
      'raffleDate',
      'description',
    ],
  },
  {
    title: 'Step 2',
    postKey: [
      'twitterLink',
      'discordLink',
      'discordServerId',
      'officialLink',
      'avatar',
      'needReCAPTCHA',
    ],
  },
  {
    title: 'Step 3',
    postKey: ['ETHorSOL'],
  },
  {
    title: 'Step 4',
    postKey: [
      'spotNums',
      'snapshotTime',
      'noticeCount',
      'candidate',
      'candidateCondition',
    ],
  },
];

function Apply() {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const [postInfo, setPostInfo] = useState({
    mintDate: new Date(),
    raffleDate: new Date(),
    needReCAPTCHA: false,
  });
  const [postValidation, setPostValidation] = useState({
    mintDate: true,
    raffleDate: true,
    needReCAPTCHA: true,
  });

  const [ETHorSOL, setETHorSOL] = useState('ETH');

  const validateInput = ({ value, type }) => {
    switch (type) {
      case 'string': {
        return !isEmpty(value) ? true : 'Cannot be empty';
      }
      case 'number': {
        return isNumber(value) ? true : 'Cannot be empty';
      }
      case 'address': {
        return ethers.utils.isAddress(value) ? true : 'Is not a valid address';
      }
      case 'date': {
        return isDate(value);
      }
      case 'url': {
        return isValidHttpUrl(value) ? true : 'Is not a valid URL';
      }
      case 'bool': {
        return isBoolean(value);
      }
      default: {
        return !isEmpty(value);
      }
    }
  };

  const handleInput = useCallback(
    (value, key, type) => {
      if (isNil(value)) return;
      setPostInfo((info) => ({
        ...info,
        [key]: value,
      }));
      setPostValidation((validation) => ({
        ...validation,
        [key]: validateInput({ value, type }),
      }));
    },
    [setPostInfo]
  );

  const handleFileChange = useCallback(
    async (e, key) => {
      const img = {
        preview: URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      };

      let formData = new FormData();
      formData.append('file', img.data);
      const response = await fetch(
        `${getBackendApi(window.location.href)}/upload_avatar`,
        {
          method: 'POST',
          body: formData,
        }
      );
      const responseWithBody = await response.json();
      if (response) {
        handleInput(responseWithBody.publicUrl, key, 'url');
      }
    },
    [handleInput]
  );

  const [isDiscordAuth, setIsDiscordAuth] = useState(false);

  const renderInputField = useCallback(
    ({ key, label, type }) => {
      const style = {
        margin: '5px 5px',
      };

      if (key === 'discordServerId') {
        return (
          <Row key={key}>
            {!isDiscordAuth && (
              <Link
                style={{ textDecoration: 'none' }}
                to={{
                  pathname:
                    'https://discord.com/api/oauth2/authorize?client_id=993047425470189568&permissions=8&scope=bot',
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledButton
                  style={{
                    border: '2px solid #cf5059',
                    borderRadius: '3px',
                    padding: '0.5rem',
                  }}
                  onClick={() => setIsDiscordAuth(true)}
                >
                  Connect Discord
                </StyledButton>
                <ThinDialogDetail>
                  👆 連結Discord驗證是否為伺服器擁有者，並輸入 server ID
                </ThinDialogDetail>
              </Link>
            )}
            {isDiscordAuth && (
              <TextField
                value={get(postInfo, key)}
                label={label}
                style={style}
                onChange={(event) => {
                  handleInput(event.target.value, key, type);
                }}
                error={get(postValidation, key) !== true}
                helperText={get(postValidation, key, 'Cannot be empty')}
              />
            )}
          </Row>
        );
      }

      switch (type) {
        case 'string': {
          return (
            <TextField
              key={key}
              value={get(postInfo, key)}
              label={label}
              style={style}
              onChange={(event) => {
                handleInput(event.target.value, key, type);
              }}
              error={get(postValidation, key) !== true}
              helperText={get(postValidation, key, 'Cannot be empty')}
            />
          );
        }
        case 'date': {
          return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} key={key}>
              <KeyboardDatePicker
                value={get(postInfo, key)}
                onChange={(date) => {
                  handleInput(date, key, type);
                }}
                label={label}
                inputFormat="MM/dd/yyyy"
                style={style}
                InputAdornmentProps={{ position: 'end' }}
              />
            </MuiPickersUtilsProvider>
          );
        }
        case 'number': {
          return (
            <TextField
              key={key}
              label={label}
              type="number"
              style={style}
              value={get(postInfo, key)}
              onChange={(event) => {
                handleInput(toNumber(event.target.value), key, type);
              }}
              error={get(postValidation, key) !== true}
              helperText={get(postValidation, key, 'Cannot be empty')}
            />
          );
        }
        case 'bool': {
          return (
            <CheckBoxContainer key={key}>
              <StyledCheckbox
                onChange={(event) => {
                  handleInput(event.target.checked, key, type);
                }}
              />
              <ThinDialogDetail style={{ color: 'black' }}>
                {label}
              </ThinDialogDetail>
            </CheckBoxContainer>
          );
        }
        case 'image': {
          return (
            <Row style={{ margin: '10px 0' }}>
              <ThinDialogDetail>Avatar</ThinDialogDetail>
              <input
                type="file"
                name="file"
                onChange={(e) => {
                  handleFileChange(e, key);
                }}
              />
            </Row>
          );
        }
        case 'arrayOf(object)': {
          return (
            <Row key={key}>
              {map(keys(postSchema[key].childSchema), (childKey) => {
                return renderInputField({
                  ...postSchema[key].childSchema[childKey],
                  key: childKey,
                });
              })}
            </Row>
          );
        }
        case 'address': {
          return (
            <TextField
              key={key}
              label={label}
              style={{
                ...style,
                width: '100%',
              }}
              value={get(postInfo, key)}
              onChange={(event) => {
                handleInput(event.target.value, key, type);
              }}
              error={get(postValidation, key) !== true}
              helperText={get(postValidation, key, 'Cannot be empty')}
            />
          );
        }
        default: {
          return (
            <TextField
              key={key}
              label={label}
              style={style}
              value={get(postInfo, key)}
              onChange={(event) => {
                handleInput(event.target.value, key, type);
              }}
              error={get(postValidation, key) !== true}
              helperText={get(postValidation, key, 'Cannot be empty')}
            />
          );
        }
      }
    },
    [postValidation, handleFileChange, handleInput, isDiscordAuth, postInfo]
  );

  const [currentProcess, setCurrentProcess] = useState(1);
  const [isValidProcess, setIsValidProcess] = useState(false);

  useEffect(() => {
    forEach(process, ({ title, postKey }) => {
      if (title === `Step ${currentProcess}`) {
        const validArr = map(postKey, (key) => get(postValidation, key));
        setIsValidProcess(postKey.length === compact(validArr).length);
      }
      // TBD: check by another way
      if (currentProcess === 3) {
        setIsValidProcess(true);
      }
    });
  }, [currentProcess, postValidation]);

  const [successMessage, setSuceessMessage] = useState(null);
  const onSubmit = useCallback(() => {
    createPost(postInfo);
    setSuceessMessage('申請成功');
    setIsOpened(false);
  }, [postInfo]);

  return (
    <div>
      <NavigationBar />
      <Container>
        <PageSection>
          <Header style={{ color: '#cf5059' }}>{applyHeaderText}</Header>
          <SubHeader>{applySubHeaderText}</SubHeader>
          <MainContainer>
            {map(applyDetials, (detail) => (
              <Row style={{ width: '30vw' }}>
                <DetailContainer>{detail}</DetailContainer>
              </Row>
            ))}
          </MainContainer>
          <CheckBoxContainer>
            <StyledCheckbox
              onChange={(event) => {
                setIsChecked(event.target.checked);
              }}
            />
            <DetailContainer>{'I accept the above conditions'}</DetailContainer>
          </CheckBoxContainer>
          <StyledButton
            disabled={!isChecked}
            onClick={() => {
              setIsOpened(true);
            }}
          >
            Next
          </StyledButton>
          <EventDialog
            open={isOpened}
            onClose={() => {
              setIsOpened(false);
            }}
          >
            {map(process, ({ title, postKey }) => {
              if (title === `Step ${currentProcess}`) {
                return (
                  <div
                    style={{
                      width: 600,
                      minHeight: 400,
                    }}
                  >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                      {map(postKey, (key) => {
                        if (title === 'Step 3') {
                          return (
                            <>
                              <Row>
                                <StyledButton
                                  style={{ padding: '0 10px 0 10px' }}
                                  onClick={() => setETHorSOL('ETH')}
                                >
                                  <img
                                    src={ethLogo}
                                    alt="eth"
                                    style={{ width: 20 }}
                                  />
                                  <ThinDialogDetail>ETH</ThinDialogDetail>
                                </StyledButton>
                                <StyledButton
                                  style={{ padding: '0 10px 0 10px' }}
                                  onClick={() => setETHorSOL('SOL')}
                                >
                                  <img
                                    src={solLogo}
                                    alt="sol"
                                    style={{ width: 20 }}
                                  />
                                  <ThinDialogDetail>SOL</ThinDialogDetail>
                                </StyledButton>
                              </Row>
                              {ETHorSOL === 'ETH' &&
                                renderInputField({
                                  ...postSchema['minETHAmount'],
                                  key: 'minETHAmount',
                                })}

                              {ETHorSOL === 'ETH' && (
                                <ThinDialogDetail style={{ color: 'black' }}>
                                  {postSchema.ownSpecificNFT.label}
                                </ThinDialogDetail>
                              )}
                              {ETHorSOL === 'ETH' &&
                                renderInputField({
                                  ...postSchema['ownSpecificNFT'],
                                  key: 'ownSpecificNFT',
                                })}
                              {ETHorSOL === 'SOL' &&
                                renderInputField({
                                  ...postSchema['minSOLAmount'],
                                  key: 'minSOLAmount',
                                })}
                            </>
                          );
                        }

                        return renderInputField({
                          ...postSchema[key],
                          key,
                        });
                      })}
                    </DialogContent>
                  </div>
                );
              }
            })}
            {currentProcess === 'Preview' && (
              <div
                style={{
                  minWidth: 1000,
                  minHeight: 400,
                }}
              >
                <PreviewEventDialog data={postInfo} />
              </div>
            )}
            {currentProcess >= 2 && (
              <StyledButton
                onClick={() => setCurrentProcess((step) => (step -= 1))}
              >
                Previous
              </StyledButton>
            )}
            {currentProcess <= 3 && (
              <StyledButton
                onClick={() => setCurrentProcess((step) => (step += 1))}
                disabled={!isValidProcess}
              >
                Next
              </StyledButton>
            )}
            {currentProcess == 4 && (
              <StyledButton
                onClick={() => setCurrentProcess('Preview')}
                disabled={!isValidProcess}
              >
                Preview
              </StyledButton>
            )}
            {currentProcess === 'Preview' && (
              <StyledButton onClick={() => setCurrentProcess(1)}>
                Edit
              </StyledButton>
            )}
            {currentProcess === 'Preview' && (
              <StyledButton onClick={onSubmit}>Submit</StyledButton>
            )}
          </EventDialog>
        </PageSection>
      </Container>
      {!isNil(successMessage) && (
        <Snackbar
          style={{ border: '1px solid rgb(204, 232, 205)' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!isNil(successMessage)}
          onClose={() => setSuceessMessage(null)}
          message={successMessage}
        />
      )}
    </div>
  );
}

export default Apply;
