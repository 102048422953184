import { Button } from '@material-ui/core';
import styled from 'styled-components';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 300px;
  padding-right: 30px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 70%;
  padding-left: 30px;
`;

const Header = styled.div`
  margin: 0 0 6px;
  padding: 0;
  text-transform: none;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: white;
  line-height: 1.46;
  font-size: 2rem;
`;

const Description = styled.div`
  margin: 0 0 6px;
  padding: 0;
  text-transform: none;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  color: white;
  line-height: 1.46;
  font-size: 1rem;
`;

const InputContainer = styled.div`
  margin: 20px 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InputLabel = styled.div`
  margin: 0 0 13px;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #8b92ad;
  line-height: 15px;
  font-size: 12px;
`;

const InputField = styled.input`
  margin: 0 0 13px;
  background: #191a35;
  border: 1px solid #4b4f73;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  line-height: 12px;
  padding: 4px 12px;
  width: calc(100% - 24px);
`;

const SubmitButton = styled(Button)`
  margin: 20px 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #cf5059, #cf5098);
  color: white;
  border-radius: 8px;
  box-shadow: inset 0 -2px 3px rgb(0 0 0 / 25%);
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 15px;
`;

const StyledButton = styled(Button)`
  color: #cf5059;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 0.4rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  width: 120px;

  &:hover {
    background-color: #cf5059;
    color: black;
    border-radius: 4px;
    border-color: #cf5059;
  }
`;

export {
  TextContainer,
  ImageContainer,
  Header,
  Description,
  InputLabel,
  InputContainer,
  InputField,
  SubmitButton,
  StyledButton,
};
