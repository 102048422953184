import { concat, get, includes, isNil, split } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, CircularProgress, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  CheckCircle,
  ClearRounded,
  NotificationsRounded,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';
import axios from 'axios';

import {
  ThinDialogDetail,
  Row,
  DialogContainer,
  DialogHeader,
  BoldDialogDetail,
  InputField,
} from '../style/pages/ApplyStyle';
import ethLogo from '../assets/eth.svg';
import discordLogo from '../assets/discord.svg';
import twitterLogo from '../assets/twitter.svg';
import AccountContext from '../context/accountContext';
import { updatePost } from '../utils/dbModels';
import { getBackendApi } from '../routes/routesConfig';

function StyledLink({ to, content }) {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={{
        pathname: to,
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <BoldDialogDetail style={{ color: '#1ca1f2' }}>
        {content}
      </BoldDialogDetail>
    </Link>
  );
}

StyledLink.propTypes = {
  to: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

function VerificationResult({ isValid, isVerifying }) {
  return (
    <>
      {isValid && !isVerifying && <CheckCircle style={{ color: '#28a744' }} />}
      {!isValid && !isVerifying && (
        <ClearRounded style={{ color: '#cf5059' }} />
      )}
    </>
  );
}

VerificationResult.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isVerifying: PropTypes.bool.isRequired,
};

function CalandarEventDialog({ data, id }) {
  const { account, currentAddress } = useContext(AccountContext);
  const [addressInput, setAddressInput] = useState(null);
  const [haveEnoughETH, setHaveEnoughETH] = useState(false);
  const onCheckETH = useCallback(async () => {
    const { ethereum } = window;
    try {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const balance = await provider.getBalance(currentAddress);

      if (ethers.utils.formatEther(balance) > get(data, 'minETHAmount')) {
        setHaveEnoughETH(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [currentAddress, data]);

  const [haveFollowTwitter, setHaveFollowTwitter] = useState(false);
  const onCheckFollowers = useCallback(async () => {
    try {
      const [, , , officialTwitterName] = split(data.twitterLink, '/');
      const response = await axios({
        url: `${getBackendApi(window.location.href)}/get_followers_by_username`,
        method: 'POST',
        data: {
          officialTwitterName,
          twitterUserId: account.twitter.id,
        },
      });
      const isFollower = response.data;
      setHaveFollowTwitter(isFollower);
    } catch (error) {
      console.error(error);
    }
  }, [data, account]);

  const [verifying, setVerifying] = useState(false);
  const [haveJoinDiscord, setHaveJoinDiscord] = useState(false);
  const onCheckDiscord = useCallback(async () => {
    try {
      const response = await axios({
        url: `${getBackendApi(window.location.href)}/is_guild_member`,
        method: 'POST',
        data: {
          discordServerId: data.discordServerId,
          discordUserId: account.discord.id,
        },
      });
      const isGuildMember = response.data;
      setHaveJoinDiscord(isGuildMember);
      setVerifying(false);
    } catch (error) {
      console.error(error);
    }
  }, [data, account]);

  const onVerification = useCallback(async () => {
    setVerifying(true);
    await onCheckETH();
    await onCheckFollowers();
    await onCheckDiscord();
  }, [onCheckETH, onCheckFollowers, onCheckDiscord]);

  const isValidRegister = useMemo(() => {
    return haveEnoughETH && haveFollowTwitter && haveJoinDiscord;
  }, [haveEnoughETH, haveFollowTwitter, haveJoinDiscord]);

  const onRegister = useCallback(async () => {
    const isDuplicate = includes(get(data, 'registerList', []), currentAddress);

    if (!isDuplicate && haveEnoughETH) {
      await updatePost(id, {
        ...data,
        registerList: concat(get(data, 'registerList', []), currentAddress),
      });
    }
  }, [currentAddress, haveEnoughETH, data, id]);

  const onJoinRemindList = useCallback(async () => {
    const isRegitered = includes(get(data, 'registerList', []), currentAddress);
    const isDuplicate = includes(
      get(data, 'raffleRemindList', []),
      currentAddress
    );

    if (isRegitered && !isDuplicate) {
      await updatePost(id, {
        ...data,
        raffleRemindList: concat(
          get(data, 'raffleRemindList', []),
          currentAddress
        ),
      });
    }
  }, [currentAddress, data, id]);

  const onCheckAddress = useCallback(() => {
    const isLottery = includes(get(data, 'lotteryList', []), currentAddress);
    //TBD: check address is in lottery list
    if (isLottery) {
      console.log('You have won the chance to MINT!');
    } else {
      console.log('You did not');
    }
  }, [currentAddress, data]);

  return (
    <DialogContent>
      <Row style={{ flexWrap: 'wrap' }}>
        <DialogContainer>
          <img
            src={get(data, 'avatar')}
            alt={'Avatar'}
            style={{ width: '300px', margin: 10 }}
          />
        </DialogContainer>
        <DialogContainer>
          <DialogHeader>{get(data, 'eventNameTitle')}</DialogHeader>
          <ThinDialogDetail>{get(data, 'projectName')}</ThinDialogDetail>
          <ThinDialogDetail>{get(data, 'description')}</ThinDialogDetail>
        </DialogContainer>
      </Row>
      <Row style={{ flexWrap: 'wrap' }}>
        <DialogContainer style={{ border: '1px solid black' }}>
          <div style={{ textAlign: 'center' }}>
            <DialogHeader>Who is Eligible</DialogHeader>
          </div>
          <div style={{ width: '100%' }}>
            <Row>
              <img
                src={ethLogo}
                alt="eth"
                style={{ width: 12, marginLeft: 10 }}
              />
              <ThinDialogDetail>{`Have at least`}</ThinDialogDetail>
              <BoldDialogDetail>
                {`${get(data, 'minETHAmount')} ETH`}
              </BoldDialogDetail>
              <ThinDialogDetail>{` in your wallet`}</ThinDialogDetail>
              <VerificationResult
                isValid={haveEnoughETH}
                isVerifying={verifying}
              />
            </Row>
            <Row>
              <img
                src={twitterLogo}
                alt="twitter"
                style={{ width: 12, marginLeft: 10 }}
              />
              <ThinDialogDetail>{`Follow `}</ThinDialogDetail>
              <StyledLink
                to={get(data, 'twitterLink')}
                content={get(data, 'twitterLink')}
              />
              <ThinDialogDetail>{` on Twitter`}</ThinDialogDetail>
              <VerificationResult
                isValid={haveFollowTwitter}
                isVerifying={verifying}
              />
            </Row>
            <Row>
              <img
                src={discordLogo}
                alt="discord"
                style={{ width: 12, marginLeft: 10 }}
              />
              <ThinDialogDetail>{`Join the`}</ThinDialogDetail>
              <StyledLink
                to={get(data, 'discordLink')}
                content={get(data, 'discordLink')}
              />
              <ThinDialogDetail>{`Discord`}</ThinDialogDetail>
              <VerificationResult
                isValid={haveJoinDiscord}
                isVerifying={verifying}
              />
            </Row>
            <Row style={{ justifyContent: 'center' }}>
              {isValidRegister ? (
                <Button
                  style={{
                    background: '#28a744',
                    color: 'white',
                    borderRadius: 10,
                    width: '80%',
                  }}
                  onClick={onRegister}
                >
                  Register
                </Button>
              ) : (
                <Button
                  style={{
                    background: '#28a744',
                    color: 'white',
                    borderRadius: 10,
                    width: '80%',
                  }}
                  onClick={onVerification}
                >
                  Check Eligible
                </Button>
              )}
              {verifying && <CircularProgress />}
            </Row>
          </div>
        </DialogContainer>
        <DialogContainer style={{ border: '1px solid black' }}>
          <ThinDialogDetail>{`Mint Date ${get(
            data,
            'mintDate'
          )}`}</ThinDialogDetail>
          <Row style={{ justifyContent: 'space-between' }}>
            <ThinDialogDetail>{`Raffle Time ${get(
              data,
              'raffleDate'
            )}`}</ThinDialogDetail>
            <Button
              style={{ padding: 0, background: 'transparent' }}
              onClick={onJoinRemindList}
            >
              <NotificationsRounded />
              <ThinDialogDetail>Remind</ThinDialogDetail>
            </Button>
          </Row>
          <Row>
            <ThinDialogDetail>{`Official Link`}</ThinDialogDetail>
            <StyledLink
              to={get(data, 'officialLink')}
              content={get(data, 'officialLink')}
            />
          </Row>
          <ThinDialogDetail>{`Enter Wallet Address (not ENS name)`}</ThinDialogDetail>
          <Row style={{ justifyContent: 'center' }}>
            <InputField
              type="address"
              placeholder={'Your address here'}
              onChange={(event) => {
                setAddressInput(event.target.value);
              }}
            />
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <Button
              style={{
                background: `${isNil(addressInput) ? '#a0a6ab' : '#28a744'}`,
                color: 'white',
                borderRadius: 10,
                width: '80%',
              }}
              onClick={() => onCheckAddress(addressInput)}
              disabled={isNil(addressInput)}
            >
              Check Address
            </Button>
          </Row>
        </DialogContainer>
      </Row>
    </DialogContent>
  );
}

CalandarEventDialog.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default CalandarEventDialog;
