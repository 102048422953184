import { Container } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { NavigationBar } from '../NavigationBar';
import {
  Header,
  PageSection,
  StyledButton,
  StyledTypography,
  SubHeader,
} from '../style/generalStyle';
import banner from '../assets/banner.jpg';

function Home() {
  const history = useHistory();
  return (
    <div>
      <NavigationBar />
      <Container>
        <PageSection>
          <img
            src={banner}
            style={{
              height: '30vh',
              padding: '1.5rem',
            }}
          />
          <Header>Welcome to Napoléon</Header>
          <SubHeader>Are you looking for opportunities on projects?</SubHeader>
          <StyledTypography>
            Register for giveaway event platform contract review and
            multi-functional NFT monitoring tool.
          </StyledTypography>
          <StyledButton
            style={{
              border: '1px solid #cf5059',
            }}
            onClick={() => {
              history.push('./list');
            }}
          >
            Launched App
          </StyledButton>
        </PageSection>
      </Container>
    </div>
  );
}

export default Home;
