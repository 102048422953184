import { createContext, useCallback, useState } from 'react';
import { get, isNil, filter, concat } from 'lodash';

import { createUser, getUserFromAddress, updateUser } from '../utils/dbModels';

const AccountContext = createContext({});

const defaultAccount = {
  mainAddress: undefined,
  subAddresses: [],
  avatarUrl: undefined,
  username: undefined,
  id: undefined,
};

const defaultSubAccount = {
  mainAddress: undefined,
  avatarUrl: undefined,
  username: undefined,
  id: undefined,
};

export function useAccountContext() {
  const [account, setAccount] = useState({});
  const [currentAddress, setCurrentAddress] = useState(
    get(account, '0.mainAddress')
  );

  const initializeAccount = useCallback(
    (mainAddress, id) => {
      setAccount({
        ...defaultAccount,
        ...mainAddress,
        ...id,
      });
    },
    [setAccount]
  );

  const refreshAccount = useCallback(
    async (web3Address) => {
      const [user] = await getUserFromAddress(web3Address);

      if (isNil(user) && !isNil(web3Address)) {
        const userInfo = await createUser(web3Address);
        initializeAccount({
          mainAddress: userInfo.mainAddress,
          id: userInfo.id,
        });
      } else {
        initializeAccount({
          mainAddress: user.data.mainAddress,
          subAddresses: get(user.data, 'subAddresses', []),
          avatarUrl: get(user.data, 'avatarUrl'),
          username: get(user.data, 'username'),
          id: user.ref.value.id,
          twitter: get(user.data, 'twitter'),
          discord: get(user.data, 'discord'),
        });
      }
    },
    [initializeAccount]
  );

  const removeAccount = useCallback(
    (address) => {
      setAccount((account) => {
        return filter(account, (account) => account.address !== address);
      });
    },
    [setAccount]
  );

  const addSubAddresses = useCallback(
    async (address) => {
      await updateUser(account.id, {
        ...account,
        subAddresses: concat(account.subAddresses, {
          defaultSubAccount,
          mainAddress: address,
        }),
      });
      await refreshAccount(account.mainAddress);
    },
    [account, refreshAccount]
  );

  return {
    account,
    refreshAccount,
    removeAccount,
    currentAddress,
    setCurrentAddress,
    addSubAddresses,
  };
}

export default AccountContext;
