const postSchema = {
  authorAddress: {
    label: 'Author Address',
    type: 'address',
  },
  eventNameTitle: {
    label: 'Event Name Title',
    type: 'string',
  },
  projectName: {
    label: 'Project Name',
    type: 'string',
  },
  registrationCloseDate: {
    label: 'Registration Close Date',
    type: 'date',
  },
  mintDate: {
    label: 'Mint Date',
    type: 'date',
  },
  spotNums: {
    label: '抽出多少名額',
    type: 'number',
  },
  snapshotTime: {
    label: '抽獎前幾多久快照一次',
    key: 'number',
  },
  raffleDate: {
    label: 'Raffle Date',
    type: 'date',
  },
  officialLink: {
    label: 'Official Link',
    type: 'url',
  },
  twitterLink: {
    label: 'Twitter Link',
    type: 'url',
  },
  discordLink: {
    label: 'Discord Link',
    type: 'url',
  },
  discordServerId: {
    label: 'Discord Server ID',
    type: 'string',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  avatar: {
    label: 'Avatar',
    type: 'image',
  },
  blackList: {
    label: '地址黑名單',
    type: 'arrayOf(address)',
  },
  minETHAmount: {
    label: '所需ETH最小餘額',
    type: 'number',
  },
  ownSpecificNFT: {
    label: '要求用戶擁有來自特定集合NFT？',
    type: 'arrayOf(object)',
    childSchema: {
      contractAddress: {
        label: 'NFT 合約地址',
        type: 'address',
      },
      collectionName: {
        label: 'NFT 集合名稱',
        type: 'string',
      },
      openseaUrl: {
        label: 'NFT 收藏連結',
        type: 'url',
      },
    },
  },
  minSOLAmount: {
    label: '所需SOL最小餘額',
    type: 'number',
  },
  teamIntroduction: {
    label: 'Team Introduction(Optional)',
    type: 'string',
  },
  noticeCount: {
    label: '中獎在notice顯示次數',
    type: 'number',
  },
  candidate: {
    label: '候選名額',
    type: 'arrayOf(address)',
  },
  candidateCondition: {
    label: '候選名額條件',
    type: 'string',
  },
  needReCAPTCHA: {
    label: '是否需要reCAPTCHA機器人',
    type: 'bool',
  },
};

export { postSchema };
