import { get } from 'lodash';
import React from 'react';
import { DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  ThinDialogDetail,
  Row,
  DialogContainer,
  DialogHeader,
  BoldDialogDetail,
} from '../style/pages/ApplyStyle';
import ethLogo from '../assets/eth.svg';
import discordLogo from '../assets/discord.svg';
import twitterLogo from '../assets/twitter.svg';

function StyledLink({ to, content }) {
  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={{
        pathname: to,
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <BoldDialogDetail style={{ color: '#1ca1f2' }}>
        {content}
      </BoldDialogDetail>
    </Link>
  );
}

StyledLink.propTypes = {
  to: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

function PreviewEventDialog({ data }) {
  return (
    <DialogContent>
      <Row style={{ flexWrap: 'wrap' }}>
        <img
          src={get(data, 'avatar')}
          alt={'Avatar'}
          style={{ width: '300px', margin: 10 }}
        />
        <DialogContainer>
          <DialogHeader>{get(data, 'eventNameTitle')}</DialogHeader>
          <ThinDialogDetail>{get(data, 'projectName')}</ThinDialogDetail>
          <ThinDialogDetail>{get(data, 'description')}</ThinDialogDetail>
        </DialogContainer>
      </Row>
      <Row style={{ flexWrap: 'wrap' }}>
        <DialogContainer style={{ border: '1px solid black' }}>
          <div style={{ textAlign: 'center' }}>
            <DialogHeader>Who is Eligible</DialogHeader>
          </div>
          <div style={{ width: '100%' }}>
            <Row>
              <img
                src={ethLogo}
                alt="eth"
                style={{ width: 12, marginLeft: 10 }}
              />
              <ThinDialogDetail>{`Have at least`}</ThinDialogDetail>
              <BoldDialogDetail>
                {`${get(data, 'minETHAmount')} ETH`}
              </BoldDialogDetail>
              <ThinDialogDetail>{` in your wallet`}</ThinDialogDetail>
            </Row>
            <Row>
              <img
                src={twitterLogo}
                alt="twitter"
                style={{ width: 12, marginLeft: 10 }}
              />
              <ThinDialogDetail>{`Follow `}</ThinDialogDetail>
              <StyledLink
                to={get(data, 'twitterLink')}
                content={get(data, 'twitterLink')}
              />
              <ThinDialogDetail>{` on Twitter`}</ThinDialogDetail>
            </Row>
            <Row>
              <img
                src={discordLogo}
                alt="discord"
                style={{ width: 12, marginLeft: 10 }}
              />
              <ThinDialogDetail>{`Join the`}</ThinDialogDetail>
              <StyledLink
                to={get(data, 'discordLink')}
                content={get(data, 'discordLink')}
              />
              <ThinDialogDetail>{`Discord`}</ThinDialogDetail>
            </Row>
          </div>
        </DialogContainer>
        <DialogContainer style={{ border: '1px solid black' }}>
          <ThinDialogDetail>{`Mint Date ${get(
            data,
            'mintDate'
          )}`}</ThinDialogDetail>
          <Row style={{ justifyContent: 'space-between' }}>
            <ThinDialogDetail>{`Raffle Time ${get(
              data,
              'raffleDate'
            )}`}</ThinDialogDetail>
          </Row>
          <Row>
            <ThinDialogDetail>{`Official Link`}</ThinDialogDetail>
            <StyledLink
              to={get(data, 'officialLink')}
              content={get(data, 'officialLink')}
            />
          </Row>
        </DialogContainer>
      </Row>
    </DialogContent>
  );
}

PreviewEventDialog.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PreviewEventDialog;
