import { Button } from '@material-ui/core';
import styled from 'styled-components';

const NoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 80vh;
`;

const EventTitle = styled.div`
  padding: 0px;
  text-align: left;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
`;

const EventDescription = styled.div`
  padding: 8px;
  text-align: left;
  color: rgb(113, 118, 123);
  font-size: 0.8rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
`;

const CheckoutButton = styled(Button)`
  width: 14vw;
  background: transparent;
  padding: 8px;
  text-align: left;
  color: #1d9bf0;
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
`;

export { NoticeContainer, EventTitle, EventDescription, CheckoutButton };
