import React from 'react';
import { Container } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { map } from 'lodash';

import { SideBar } from '../SideBar';
import { NavigationBar } from '../NavigationBar';
import {
  Column,
  FilledButton,
  PageSection,
  StyledTypography,
} from '../style/generalStyle';

import { listButtonText } from '../constant/context';

function List() {
  const history = useHistory();

  return (
    <div>
      <NavigationBar />
      <SideBar />
      <Container>
        <PageSection
          style={{
            flexDirection: 'row',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          }}
        >
          {map(listButtonText, ({ buttonText, description }) => (
            <Column
              key={buttonText}
              style={{ width: '250px', alignItems: 'flex-start' }}
            >
              <FilledButton
                style={{ width: '100%' }}
                onClick={() => {
                  history.push('./apply');
                }}
              >
                {buttonText}
              </FilledButton>
              {map(description, (des) => (
                <StyledTypography style={{ textAlign: 'left', width: '250px' }}>
                  {des}
                </StyledTypography>
              ))}
            </Column>
          ))}
        </PageSection>
      </Container>
    </div>
  );
}

export default List;
