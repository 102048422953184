import styled from 'styled-components';
import { Typography, IconButton, Toolbar } from '@material-ui/core';

const StyledToolbar = styled(Toolbar)`
  display: flex;
  zindex: 10;
  width: 100%;
  padding: 0;
  top: 30vh;
  left: 10px;
`;
const IconTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 12vw;
`;

const SideBarButton = styled(Typography)`
  width: 100%;
  justify-content: flex-start;
  color: #cf5059;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding: 0.5rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;

  &:hover {
    border: 1px solid #cf5059;
    border-radius: 4px;
    border-color: #cf5059;
  }
`;

const SideBarIcon = styled(IconButton)`
  color: #cf5059;
  padding: 3px;
`;

export { StyledToolbar, IconTextContainer, SideBarButton, SideBarIcon };
