import { Accordion } from '@material-ui/core';
import styled from 'styled-components';

const MainContainer = styled.div`
  margin: 10vh 0 5vh;
  max-width: 40vw;
  border: 1px solid #cf5059;
  padding: 24px;
  text-align: left;
  color: white;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
`;

const DetailContainer = styled.div`
  width: 30vw;
  justify-contents: flex-start;
  margin: auto;
  border: 1px solid #cf5059;
  padding: 24px;
  text-align: left;
  color: white;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
`;

const StyledAccordion = styled(Accordion)`
  width: 40vw;
  background-color: black;
  color: white;

  && .MuiAccordionSummary-root {
    width: 40vw;
    background-color: black;
    color: white;
    border: 1px solid #cf5059;
    margin: 5px 0;
  }
`;

export { MainContainer, DetailContainer, StyledAccordion };
