const aboutMain =
  'Register for giveaway event platform contract review and multi-functional NFT monitoring tool. Technical support from Mepunk.';

const aboutDetails = [
  'Launched NFT monitoring tool',
  'Optimize and upgrade',
  'technical support project launch',
  'Launched NFT monitoring tool',
];

const FAQDetails = [
  {
    question: 'What kind of services you provide?',
    answer:
      'Our service provides a convenient registration platform, and projects are welcome to cooperate with us. We also assist in launching, and the update records will be displayed on discord.',
  },
  {
    question: 'What secondary royalties be used?',
    answer:
      'we will use the fund to hire more full-time team members,operations, and deliver even more value to Napoléon!',
  },
  {
    question: 'about sale',
    answer:
      'After the whitelist is mint, the remaining quantity will be sold to the public. If there is any remaining, it will be burned directly.',
  },
  {
    question: 'Do you have any plans after the sale?',
    answer:
      'Optimize platform performance and update based on feedback. Develop multi-function tools to make it easier for users to use.',
  },
];

const applyHeaderText = 'To create your project whitelist event';

const applySubHeaderText = 'If you want to submit your project';

const applyDetials = [
  '1. Please make sure to submit the correct information',
  '2. If there is any problem with the project, the platform will not be responsible for any loss compensation',
  "3. Please don't do rug project",
  '4. It will take a few days for review after submission',
  '5. Please ensure that the uploaded information is correct and do not spread false information',
];

const listButtonText = [
  {
    buttonText: 'Create raffle for your project',
    description: [
      'We provide the best stage for your project',
      'Click to learn more',
    ],
  },
  {
    buttonText: "Check out the listed you've registered",
    description: [
      'One-click deletion of registered items',
      'Proactively remind the winning notification',
    ],
  },
];

const profileDetails = {
  wallets:
    'You can connect up to 3 wallets to your account. This lets you verify token ownership with one wallet and submit a different wallet for the mint list.',
  socialAccounts:
    'Some projects ask you to connect a social network during the registration process. You can only connect a specific social account to one PREMINT account.',
};

export {
  aboutMain,
  aboutDetails,
  FAQDetails,
  applyHeaderText,
  applySubHeaderText,
  applyDetials,
  listButtonText,
  profileDetails,
};
